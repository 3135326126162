export const getAuthorizerError = (gatewayAuthorizer, entryMode, error) => {
  const isCNP = cnpEntryModes.includes(entryMode)
  if (isCNP && error?.responseMessage) {
    return {
      definition: error.responseMessage,
      action: ''
    }
  }

  if (['cielo'].includes(gatewayAuthorizer)) {
    return byResponseCode.cielo[error.responseCode]
  }

  if (['rede', 'rede_pdv'].includes(gatewayAuthorizer)) {
    return byResponseCode.rede.cp[error.responseCode]
  }
}

const cnpEntryModes = ['digital_wallet', 'manually_keyed', 'manually_keyed_fallback', 'three_d_secure']

const byResponseCode = {
  // CIELO -> não diferencia as mensagens por CP e CNP
  cielo: {
    '00': {
      definition: 'Transação autorizada com sucesso.',
      meaning: 'Transação autorizada com sucesso.',
      action: 'Transação autorizada com sucesso.',
      allows_retrying: 'Não'
    },
    '000': {
      definition: 'Transação autorizada com sucesso.',
      meaning: 'Transação autorizada com sucesso.',
      action: 'Transação autorizada com sucesso.',
      allows_retrying: 'Não'
    },
    '01': {
      definition: 'Transação não autorizada. Transação referida.',
      meaning: 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '02': {
      definition: 'Transação não autorizada. Transação referida.',
      meaning: 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '03': {
      definition: 'Transação não permitida. Erro no cadastramento do código do estabelecimento no arquivo de configuração do TEF',
      meaning: 'Transação não permitida. Estabelecimento inválido. Entre com contato com a Cielo.',
      action: 'Não foi possível processar a transação. Entre com contato com a Loja Virtual.',
      allows_retrying: 'Não'
    },
    '04': {
      definition: 'Transação não autorizada. Cartão bloqueado pelo banco emissor.',
      meaning: 'Transação não autorizada. Cartão bloqueado pelo banco emissor.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '05': {
      definition: 'Transação não autorizada. Cartão inadimplente (Do not honor).',
      meaning: 'Transação não autorizada. Não foi possível processar a transação. Questão relacionada a segurança, inadimplencia ou limite do portador.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '06': {
      definition: 'Transação não autorizada. Cartão cancelado.',
      meaning: 'Transação não autorizada. Não foi possível processar a transação. Cartão cancelado permanentemente pelo banco emissor.',
      action: 'Não foi possível processar a transação. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '07': {
      definition: 'Transação negada. Reter cartão condição especial',
      meaning: 'Transação não autorizada por regras do banco emissor.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor',
      allows_retrying: 'Não'
    },
    '08': {
      definition: 'Transação não autorizada. Código de segurança inválido.',
      meaning: 'Transação não autorizada. Código de segurança inválido. Oriente o portador a corrigir os dados e tentar novamente.',
      action: 'Transação não autorizada. Dados incorretos. Reveja os dados e informe novamente.',
      allows_retrying: 'Não'
    },
    '11': {
      definition: 'Transação autorizada com sucesso para cartão emitido no exterior',
      meaning: 'Transação autorizada com sucesso.',
      action: 'Transação autorizada com sucesso.',
      allows_retrying: 'Não'
    },
    '12': {
      definition: 'Transação inválida, erro no cartão.',
      meaning: 'Não foi possível processar a transação. Solicite ao portador que verifique os dados do cartão e tente novamente.',
      action: 'Não foi possível processar a transação. reveja os dados informados e tente novamente. Se o erro persistir, entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '13': {
      definition: 'Transação não permitida. Valor da transação Inválido.',
      meaning: 'Transação não permitida. Valor inválido. Solicite ao portador que reveja os dados e novamente. Se o erro persistir, entre em contato com a Cielo.',
      action: 'Transação não autorizada. Valor inválido. Refazer a transação confirmando os dados informados. Persistindo o erro, entrar em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '14': {
      definition: 'Transação não autorizada. Cartão Inválido',
      meaning: 'Transação não autorizada. Cartão inválido. Pode ser bloqueio do cartão no banco emissor, dados incorretos ou tentativas de testes de cartão. Use o Algoritmo de Lhum (Mod 10) para evitar transações não autorizadas por esse motivo. Consulte www.cielo.com.br/desenvolvedores para implantar o Algoritmo de Lhum.',
      action: 'Não foi possível processar a transação. reveja os dados informados e tente novamente. Se o erro persistir, entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '15': {
      definition: 'Banco emissor indisponível ou inexistente.',
      meaning: 'Transação não autorizada. Banco emissor indisponível.',
      action: 'Não foi possível processar a transação. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '19': {
      definition: 'Refaça a transação ou tente novamente mais tarde.',
      meaning: 'Não foi possível processar a transação. Refaça a transação ou tente novamente mais tarde. Se o erro persistir, entre em contato com a Cielo.',
      action: 'Não foi possível processar a transação. Refaça a transação ou tente novamente mais tarde. Se o erro persistir entre em contato com a loja virtual.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '21': {
      definition: 'Cancelamento não efetuado. Transação não localizada.',
      meaning: 'Não foi possível processar o cancelamento. Se o erro persistir, entre em contato com a Cielo.',
      action: 'Não foi possível processar o cancelamento. Tente novamente mais tarde. Persistindo o erro, entrar em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '22': {
      definition: 'Parcelamento inválido. Número de parcelas inválidas.',
      meaning: 'Não foi possível processar a transação. Número de parcelas inválidas. Se o erro persistir, entre em contato com a Cielo.',
      action: 'Não foi possível processar a transação. Valor inválido. Refazer a transação confirmando os dados informados. Persistindo o erro, entrar em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '23': {
      definition: 'Transação não autorizada. Valor da prestação inválido.',
      meaning: 'Não foi possível processar a transação. Valor da prestação inválido. Se o erro persistir, entre em contato com a Cielo.',
      action: 'Não foi possível processar a transação. Valor da prestação inválido. Refazer a transação confirmando os dados informados. Persistindo o erro, entrar em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '24': {
      definition: 'Quantidade de parcelas inválido.',
      meaning: 'Não foi possível processar a transação. Quantidade de parcelas inválido. Se o erro persistir, entre em contato com a Cielo.',
      action: 'Não foi possível processar a transação. Quantidade de parcelas inválido. Refazer a transação confirmando os dados informados. Persistindo o erro, entrar em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '25': {
      definition: 'Pedido de autorização não enviou número do cartão',
      meaning: 'Não foi possível processar a transação. Solicitação de autorização não enviou o número do cartão. Se o erro persistir, verifique a comunicação entre loja virtual e Cielo.',
      action: 'Não foi possível processar a transação. reveja os dados informados e tente novamente. Persistindo o erro, entrar em contato com a loja virtual.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '28': {
      definition: 'Arquivo temporariamente indisponível.',
      meaning: 'Não foi possível processar a transação. Arquivo temporariamente indisponível. Reveja a comunicação entre Loja Virtual e Cielo. Se o erro persistir, entre em contato com a Cielo.',
      action: 'Não foi possível processar a transação. Entre com contato com a Loja Virtual.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '30': {
      definition: 'Transação não autorizada. Decline Message',
      meaning: 'Não foi possível processar a transação. Solicite ao portador que reveja os dados e tente novamente. Se o erro persistir verifique a comunicação com a Cielo esta sendo feita corretamente',
      action: 'Não foi possível processar a transação. Reveja os dados e tente novamente. Se o erro persistir, entre em contato com a loja',
      allows_retrying: 'Não'
    },
    '39': {
      definition: 'Transação não autorizada. Erro no banco emissor.',
      meaning: 'Transação não autorizada. Erro no banco emissor.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '41': {
      definition: 'Transação não autorizada. Cartão bloqueado por perda.',
      meaning: 'Transação não autorizada. Cartão bloqueado por perda.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '43': {
      definition: 'Transação não autorizada. Cartão bloqueado por roubo.',
      meaning: 'Transação não autorizada. Cartão bloqueado por roubo.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '51': {
      definition: 'Transação não autorizada. Limite excedido/sem saldo.',
      meaning: 'Transação não autorizada. Limite excedido/sem saldo.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'A partir do dia seguinte, apenas 4 vezes em 16 dias.'
    },
    '52': {
      definition: 'Cartão com dígito de controle inválido.',
      meaning: 'Não foi possível processar a transação. Cartão com dígito de controle inválido.',
      action: 'Transação não autorizada. Reveja os dados informados e tente novamente.',
      allows_retrying: 'Não'
    },
    '53': {
      definition: 'Transação não permitida. Cartão poupança inválido',
      meaning: 'Transação não permitida. Cartão poupança inválido.',
      action: 'Não foi possível processar a transação. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '54': {
      definition: 'Transação não autorizada. Cartão vencido',
      meaning: 'Transação não autorizada. Cartão vencido.',
      action: 'Transação não autorizada. Refazer a transação confirmando os dados.',
      allows_retrying: 'Não'
    },
    '55': {
      definition: 'Transação não autorizada. Senha inválida',
      meaning: 'Transação não autorizada. Senha inválida.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '57': {
      definition: 'Transação não permitida para o cartão',
      meaning: 'Transação não autorizada. Transação não permitida para o cartão.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '58': {
      definition: 'Transação não permitida. Opção de pagamento inválida.',
      meaning: 'Transação não permitida. Opção de pagamento inválida. Reveja se a opção de pagamento escolhida está habilitada no cadastro',
      action: 'Transação não autorizada. Entre em contato com sua loja virtual.',
      allows_retrying: 'Não'
    },
    '59': {
      definition: 'Transação não autorizada. Suspeita de fraude.',
      meaning: 'Transação não autorizada. Suspeita de fraude.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '60': {
      definition: 'Transação não autorizada.',
      meaning: 'Transação não autorizada. Tente novamente. Se o erro persistir o portador deve entrar em contato com o banco emissor.',
      action: 'Não foi possível processar a transação. Tente novamente mais tarde. Se o erro persistir, entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '61': {
      definition: 'Banco emissor indisponível.',
      meaning: 'Transação não autorizada. Banco emissor indisponível.',
      action: 'Transação não autorizada. Tente novamente. Se o erro persistir, entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '62': {
      definition: 'Transação não autorizada. Cartão restrito para uso doméstico',
      meaning: 'Transação não autorizada. Cartão restrito para uso doméstico.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'A partir do dia seguinte, apenas 4 vezes em 16 dias.'
    },
    '63': {
      definition: 'Transação não autorizada. Violação de segurança',
      meaning: 'Transação não autorizada. Violação de segurança.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '64': {
      definition: 'Transação não autorizada. Valor abaixo do mínimo exigido pelo banco emissor.',
      meaning: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      action: 'Transação não autorizada. Valor abaixo do mínimo exigido pelo banco emissor.',
      allows_retrying: 'Não'
    },
    '65': {
      definition: 'Transação não autorizada. Excedida a quantidade de transações para o cartão.',
      meaning: 'Transação não autorizada. Excedida a quantidade de transações para o cartão.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '67': {
      definition: 'Transação não autorizada. Cartão bloqueado para compras hoje.',
      meaning: 'Transação não autorizada. Cartão bloqueado para compras hoje. Bloqueio pode ter ocorrido por excesso de tentativas inválidas. O cartão será desbloqueado automaticamente à meia noite.',
      action: 'Transação não autorizada. Cartão bloqueado temporariamente. Entre em contato com seu banco emissor.',
      allows_retrying: 'A partir do dia seguinte, apenas 4 vezes em 16 dias.'
    },
    '70': {
      definition: 'Transação não autorizada. Limite excedido/sem saldo.',
      meaning: 'Transação não autorizada. Limite excedido/sem saldo.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'A partir do dia seguinte, apenas 4 vezes em 16 dias.'
    },
    '72': {
      definition: 'Cancelamento não efetuado. Saldo disponível para cancelamento insuficiente.',
      meaning: 'Cancelamento não efetuado. Saldo disponível para cancelamento insuficiente. Se o erro persistir, entre em contato com a Cielo.',
      action: 'Cancelamento não efetuado. Tente novamente mais tarde. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '74': {
      definition: 'Transação não autorizada. A senha está vencida.',
      meaning: 'Transação não autorizada. A senha está vencida.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '75': {
      definition: 'Senha bloqueada. Excedeu tentativas de cartão.',
      meaning: 'Transação não autorizada.',
      action: 'Sua Transação não pode ser processada. Entre em contato com o Emissor do seu cartão.',
      allows_retrying: 'Não'
    },
    '76': {
      definition: 'Cancelamento não efetuado. Banco emissor não localizou a transação original',
      meaning: 'Cancelamento não efetuado. Banco emissor não localizou a transação original',
      action: 'Cancelamento não efetuado. Entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '77': {
      definition: 'Cancelamento não efetuado. Não foi localizado a transação original',
      meaning: 'Cancelamento não efetuado. Não foi localizado a transação original',
      action: 'Cancelamento não efetuado. Entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '78': {
      definition: 'Transação não autorizada. Cartão bloqueado primeiro uso.',
      meaning: 'Transação não autorizada. Cartão bloqueado primeiro uso. Solicite ao portador que desbloqueie o cartão diretamente com seu banco emissor.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor e solicite o desbloqueio do cartão.',
      allows_retrying: 'Não'
    },
    '80': {
      definition: 'Transação não autorizada. Divergencia na data de transação/pagamento.',
      meaning: 'Transação não autorizada. Data da transação ou data do primeiro pagamento inválida.',
      action: 'Transação não autorizada. Refazer a transação confirmando os dados.',
      allows_retrying: 'Não'
    },
    '82': {
      definition: 'Transação não autorizada. Cartão inválido.',
      meaning: 'Transação não autorizada. Cartão Inválido. Solicite ao portador que reveja os dados e tente novamente.',
      action: 'Transação não autorizada. Refazer a transação confirmando os dados. Se o erro persistir, entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '83': {
      definition: 'Transação não autorizada. Erro no controle de senhas',
      meaning: 'Transação não autorizada. Erro no controle de senhas',
      action: 'Transação não autorizada. Refazer a transação confirmando os dados. Se o erro persistir, entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    '85': {
      definition: 'Transação não permitida. Falha da operação.',
      meaning: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo.',
      action: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '86': {
      definition: 'Transação não permitida. Falha da operação.',
      meaning: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo.',
      action: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '89': {
      definition: 'Erro na transação.',
      meaning: 'Transação não autorizada. Erro na transação. O portador deve tentar novamente e se o erro persistir, entrar em contato com o banco emissor.',
      action: 'Transação não autorizada. Erro na transação. Tente novamente e se o erro persistir, entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '90': {
      definition: 'Transação não permitida. Falha da operação.',
      meaning: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo.',
      action: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '91': {
      definition: 'Transação não autorizada. Banco emissor temporariamente indisponível.',
      meaning: 'Transação não autorizada. Banco emissor temporariamente indisponível.',
      action: 'Transação não autorizada. Banco emissor temporariamente indisponível. Entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '92': {
      definition: 'Transação não autorizada. Tempo de comunicação excedido.',
      meaning: 'Transação não autorizada. Tempo de comunicação excedido.',
      action: 'Transação não autorizada. Comunicação temporariamente indisponível. Entre em contato com a loja virtual.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '93': {
      definition: 'Transação não autorizada. Violação de regra - Possível erro no cadastro.',
      meaning: 'Transação não autorizada. Violação de regra - Possível erro no cadastro.',
      action: 'Sua transação não pode ser processada. Entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    '96': {
      definition: 'Falha no processamento.',
      meaning: 'Não foi possível processar a transação. Falha no sistema da Cielo. Se o erro persistir, entre em contato com a Cielo.',
      action: 'Sua Transação não pode ser processada, Tente novamente mais tarde. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '97': {
      definition: 'Valor não permitido para essa transação.',
      meaning: 'Transação não autorizada. Valor não permitido para essa transação.',
      action: 'Transação não autorizada. Valor não permitido para essa transação.',
      allows_retrying: 'Não'
    },
    '98': {
      definition: 'Sistema/comunicação indisponível.',
      meaning: 'Transação não autorizada. Sistema do emissor sem comunicação. Se for geral, verificar SITEF, GATEWAY e/ou Conectividade.',
      action: 'Sua Transação não pode ser processada, Tente novamente mais tarde. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    '99': {
      definition: 'Sistema/comunicação indisponível.',
      meaning: 'Transação não autorizada. Sistema do emissor sem comunicação. Tente mais tarde. Pode ser erro no SITEF, favor verificar !',
      action: 'Sua Transação não pode ser processada, Tente novamente mais tarde. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'A partir do dia seguinte, apenas 4 vezes em 16 dias.'
    },
    '999': {
      definition: 'Sistema/comunicação indisponível.',
      meaning: 'Transação não autorizada. Sistema do emissor sem comunicação. Tente mais tarde. Pode ser erro no SITEF, favor verificar !',
      action: 'Sua Transação não pode ser processada, Tente novamente mais tarde. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'A partir do dia seguinte, apenas 4 vezes em 16 dias.'
    },
    'A1': {
      definition: 'Estabelecimento não cadastrado',
      meaning: '',
      action: '',
      allows_retrying: 'Não'
    },
    'A3': {
      definition: '',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'AP-TRANSL HSM HW': {
      definition: 'Erro de Translate da Chave de Criptografia no HSM',
      meaning: 'Tempo excedido na comunicação com o banco emissor. Oriente o portador a tentar novamente, se o erro persistir será necessário que o portador contate seu banco emissor.',
      action: 'Tempo excedido na sua comunicação com o banco emissor, tente novamente mais tarde. Se o erro persistir, entre em contato com seu banco.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    'AA': {
      definition: 'Tempo Excedido',
      meaning: 'Tempo excedido na comunicação com o banco emissor. Oriente o portador a tentar novamente, se o erro persistir será necessário que o portador contate seu banco emissor.',
      action: 'Tempo excedido na sua comunicação com o banco emissor, tente novamente mais tarde. Se o erro persistir, entre em contato com seu banco.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    'AC': {
      definition: 'Transação não permitida. Cartão de débito sendo usado com crédito. Use a função débito.',
      meaning: 'Transação não permitida. Cartão de débito sendo usado com crédito. Solicite ao portador que selecione a opção de pagamento Cartão de Débito.',
      action: 'Transação não autorizada. Tente novamente selecionando a opção de pagamento cartão de débito.',
      allows_retrying: 'Não'
    },
    'AE': {
      definition: 'Tente Mais Tarde',
      meaning: 'Tempo excedido na comunicação com o banco emissor. Oriente o portador a tentar novamente, se o erro persistir será necessário que o portador contate seu banco emissor.',
      action: 'Tempo excedido na sua comunicação com o banco emissor, tente novamente mais tarde. Se o erro persistir, entre em contato com seu banco.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    'AF': {
      definition: 'Transação não permitida. Falha da operação.',
      meaning: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo.',
      action: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    'AG': {
      definition: 'Transação não permitida. Falha da operação.',
      meaning: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo.',
      action: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    'AH': {
      definition: 'Transação não permitida. Cartão de crédito sendo usado com débito. Use a função crédito.',
      meaning: 'Transação não permitida. Cartão de crédito sendo usado com débito. Solicite ao portador que selecione a opção de pagamento Cartão de Crédito.',
      action: 'Transação não autorizada. Tente novamente selecionando a opção de pagamento cartão de crédito.',
      allows_retrying: 'Não'
    },
    'AI': {
      definition: 'Transação não autorizada. Autenticação não foi realizada.',
      meaning: 'Transação não autorizada. Autenticação não foi realizada. O portador não concluiu a autenticação. Solicite ao portador que reveja os dados e tente novamente. Se o erro persistir, entre em contato com a Cielo informando o BIN (6 primeiros dígitos do cartão)',
      action: 'Transação não autorizada. Autenticação não foi realizada com sucesso. Tente novamente e informe corretamente os dados solicitado. Se o erro persistir, entre em contato com o lojista.',
      allows_retrying: 'Não'
    },
    'AJ': {
      definition: 'Transação não permitida. Transação de crédito ou débito em uma operação que permite apenas Private Label. Tente novamente selecionando a opção Private Label.',
      meaning: 'Transação não permitida. Transação de crédito ou débito em uma operação que permite apenas Private Label. Solicite ao portador que tente novamente selecionando a opção Private Label. Caso não disponibilize a opção Private Label verifique na Cielo se o seu estabelecimento permite essa operação.',
      action: 'Transação não permitida. Transação de crédito ou débito em uma operação que permite apenas Private Label. Tente novamente e selecione a opção Private Label. Em caso de um novo erro entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    'AV': {
      definition: 'Transação não autorizada. Dados Inválidos',
      meaning: 'Falha na validação dos dados da transação. Oriente o portador a rever os dados e tentar novamente.',
      action: 'Falha na validação dos dados. Reveja os dados informados e tente novamente.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    'BD': {
      definition: 'Transação não permitida. Falha da operação.',
      meaning: 'Transação não permitida. Houve um erro no processamento.Solicite ao portador que digite novamente os dados do cartão, se o erro persistir pode haver um problema no terminal do lojista, nesse caso o lojista deve entrar em contato com a Cielo.',
      action: 'Transação não permitida. Informe os dados do cartão novamente. Se o erro persistir, entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    'BL': {
      definition: 'Transação não autorizada. Limite diário excedido.',
      meaning: 'Transação não autorizada. Limite diário excedido. Solicite ao portador que entre em contato com seu banco emissor.',
      action: 'Transação não autorizada. Limite diário excedido. Entre em contato com seu banco emissor.',
      allows_retrying: 'A partir do dia seguinte, apenas 4 vezes em 16 dias.'
    },
    'BM': {
      definition: 'Transação não autorizada. Cartão Inválido',
      meaning: 'Transação não autorizada. Cartão inválido. Pode ser bloqueio do cartão no banco emissor ou dados incorretos. Tente usar o Algoritmo de Lhum (Mod 10) para evitar transações não autorizadas por esse motivo.',
      action: 'Transação não autorizada. Cartão inválido. Refaça a transação confirmando os dados informados.',
      allows_retrying: 'Não'
    },
    'BN': {
      definition: 'Transação não autorizada. Cartão ou conta bloqueado.',
      meaning: 'Transação não autorizada. O cartão ou a conta do portador está bloqueada. Solicite ao portador que entre em contato com seu banco emissor.',
      action: 'Transação não autorizada. O cartão ou a conta do portador está bloqueada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    'BO': {
      definition: 'Transação não permitida. Falha da operação.',
      meaning: 'Transação não permitida. Houve um erro no processamento. Solicite ao portador que digite novamente os dados do cartão, se o erro persistir, entre em contato com o banco emissor.',
      action: 'Transação não permitida. Houve um erro no processamento. Digite novamente os dados do cartão, se o erro persistir, entre em contato com o banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    'BP': {
      definition: 'Transação não autorizada. Conta corrente inexistente.',
      meaning: 'Transação não autorizada. Não possível processar a transação por um erro relacionado ao cartão ou conta do portador. Solicite ao portador que entre em contato com o banco emissor.',
      action: 'Transação não autorizada. Não possível processar a transação por um erro relacionado ao cartão ou conta do portador. Entre em contato com o banco emissor.',
      allows_retrying: 'Não'
    },
    'BV': {
      definition: 'Transação não autorizada. Cartão vencido',
      meaning: 'Transação não autorizada. Cartão vencido.',
      action: 'Transação não autorizada. Refazer a transação confirmando os dados.',
      allows_retrying: 'Não'
    },
    'CF': {
      definition: 'Transação não autorizada.C79:J79 Falha na validação dos dados.',
      meaning: 'Transação não autorizada. Falha na validação dos dados. Solicite ao portador que entre em contato com o banco emissor.',
      action: 'Transação não autorizada. Falha na validação dos dados. Entre em contato com o banco emissor.',
      allows_retrying: 'Não'
    },
    'CG': {
      definition: 'Transação não autorizada. Falha na validação dos dados.',
      meaning: 'Transação não autorizada. Falha na validação dos dados. Solicite ao portador que entre em contato com o banco emissor.',
      action: 'Transação não autorizada. Falha na validação dos dados. Entre em contato com o banco emissor.',
      allows_retrying: 'Não'
    },
    'DA': {
      definition: 'Transação não autorizada. Falha na validação dos dados.',
      meaning: 'Transação não autorizada. Falha na validação dos dados. Solicite ao portador que entre em contato com o banco emissor.',
      action: 'Transação não autorizada. Falha na validação dos dados. Entre em contato com o banco emissor.',
      allows_retrying: 'Não'
    },
    'DF': {
      definition: 'Transação não permitida. Falha no cartão ou cartão inválido.',
      meaning: 'Transação não permitida. Falha no cartão ou cartão inválido. Solicite ao portador que digite novamente os dados do cartão, se o erro persistir, entre em contato com o banco',
      action: 'Transação não permitida. Falha no cartão ou cartão inválido. Digite novamente os dados do cartão, se o erro persistir, entre em contato com o banco',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    'DM': {
      definition: 'Transação não autorizada. Limite excedido/sem saldo.',
      meaning: 'Transação não autorizada. Limite excedido/sem saldo.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'A partir do dia seguinte, apenas 4 vezes em 16 dias.'
    },
    'DQ': {
      definition: 'Transação não autorizada. Falha na validação dos dados.',
      meaning: 'Transação não autorizada. Falha na validação dos dados. Solicite ao portador que entre em contato com o banco emissor.',
      action: 'Transação não autorizada. Falha na validação dos dados. Entre em contato com o banco emissor.',
      allows_retrying: 'Não'
    },
    'DS': {
      definition: 'Transação não permitida para o cartão',
      meaning: 'Transação não autorizada. Transação não permitida para o cartão.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    'EB': {
      definition: 'Transação não autorizada. Limite diário excedido.',
      meaning: 'Transação não autorizada. Limite diário excedido. Solicite ao portador que entre em contato com seu banco emissor.',
      action: 'Transação não autorizada. Limite diário excedido. Entre em contato com seu banco emissor.',
      allows_retrying: 'A partir do dia seguinte, apenas 4 vezes em 16 dias.'
    },
    'EE': {
      definition: 'Transação não permitida. Valor da parcela inferior ao mínimo permitido.',
      meaning: 'Transação não permitida. Valor da parcela inferior ao mínimo permitido. Não é permitido parcelas inferiores a R$ 5,00. Necessário rever calculo para parcelas.',
      action: 'Transação não permitida. O valor da parcela está abaixo do mínimo permitido. Entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    'EK': {
      definition: 'Transação não permitida para o cartão',
      meaning: 'Transação não autorizada. Transação não permitida para o cartão.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    'FA': {
      definition: 'Transação não autorizada.',
      meaning: 'Transação não autorizada AmEx.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    'FC': {
      definition: 'Transação não autorizada. Ligue Emissor',
      meaning: 'Transação não autorizada. Oriente o portador a entrar em contato com o banco emissor.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Não'
    },
    'FD': {
      definition: 'Transação negada. Reter cartão condição especial',
      meaning: 'Transação não autorizada por regras do banco emissor.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor',
      allows_retrying: 'Não'
    },
    'FE': {
      definition: 'Transação não autorizada. Divergencia na data de transação/pagamento.',
      meaning: 'Transação não autorizada. Data da transação ou data do primeiro pagamento inválida.',
      action: 'Transação não autorizada. Refazer a transação confirmando os dados.',
      allows_retrying: 'Não'
    },
    'FF': {
      definition: 'Cancelamento OK',
      meaning: 'Transação de cancelamento autorizada com sucesso. ATENÇÂO: Esse retorno é para casos de cancelamentos e não para casos de autorizações.',
      action: 'Transação de cancelamento autorizada com sucesso',
      allows_retrying: 'Não'
    },
    'FG': {
      definition: 'Transação não autorizada. Ligue 08007285090.',
      meaning: 'Transação não autorizada. Oriente o portador a entrar em contato com a Central de Atendimento AmEx.',
      action: 'Transação não autorizada. Entre em contato com a Central de Atendimento AmEx no telefone 08007285090',
      allows_retrying: 'Não'
    },
    'GA': {
      definition: 'Aguarde Contato',
      meaning: 'Transação não autorizada. Referida pelo Lynx Online de forma preventiva. A Cielo entrará em contato com o lojista sobre esse caso.',
      action: 'Transação não autorizada. Entre em contato com o lojista.',
      allows_retrying: 'Não'
    },
    'HJ': {
      definition: 'Transação não permitida. Código da operação inválido.',
      meaning: 'Transação não permitida. Código da operação Coban inválido.',
      action: 'Transação não permitida. Código da operação Coban inválido. Entre em contato com o lojista.',
      allows_retrying: 'Não'
    },
    'IA': {
      definition: 'Transação não permitida. Indicador da operação inválido.',
      meaning: 'Transação não permitida. Indicador da operação Coban inválido.',
      action: 'Transação não permitida. Indicador da operação Coban inválido. Entre em contato com o lojista.',
      allows_retrying: 'Não'
    },
    'JB': {
      definition: 'Transação não permitida. Valor da operação inválido.',
      meaning: 'Transação não permitida. Valor da operação Coban inválido.',
      action: 'Transação não permitida. Valor da operação Coban inválido. Entre em contato com o lojista.',
      allows_retrying: 'Não'
    },
    'KA': {
      definition: 'Transação não permitida. Falha na validação dos dados.',
      meaning: 'Transação não permitida. Houve uma falha na validação dos dados. Solicite ao portador que reveja os dados e tente novamente. Se o erro persistir verifique a comunicação entre loja virtual e Cielo.',
      action: 'Transação não permitida. Houve uma falha na validação dos dados. reveja os dados informados e tente novamente. Se o erro persistir entre em contato com a Loja Virtual.',
      allows_retrying: 'Não'
    },
    'KB': {
      definition: 'Transação não permitida. Selecionado a opção incorrente.',
      meaning: 'Transação não permitida. Selecionado a opção incorreta. Solicite ao portador que reveja os dados e tente novamente. Se o erro persistir deve ser verificado a comunicação entre loja virtual e Cielo.',
      action: 'Transação não permitida. Selecionado a opção incorreta. Tente novamente. Se o erro persistir entre em contato com a Loja Virtual.',
      allows_retrying: 'Não'
    },
    'KE': {
      definition: 'Transação não autorizada. Falha na validação dos dados.',
      meaning: 'Transação não autorizada. Falha na validação dos dados. Opção selecionada não está habilitada. Verifique as opções disponíveis para o portador.',
      action: 'Transação não autorizada. Falha na validação dos dados. Opção selecionada não está habilitada. Entre em contato com a loja virtual.',
      allows_retrying: 'Não'
    },
    'N7': {
      definition: 'Transação não autorizada. Código de segurança inválido.',
      meaning: 'Transação não autorizada. Código de segurança inválido. Oriente o portador corrigir os dados e tentar novamente.',
      action: 'Transação não autorizada. Reveja os dados e informe novamente.',
      allows_retrying: 'Não'
    },
    'R1': {
      definition: 'Transação não autorizada. Cartão inadimplente (Do not honor).',
      meaning: 'Transação não autorizada. Não foi possível processar a transação. Questão relacionada a segurança, inadimplencia ou limite do portador.',
      action: 'Transação não autorizada. Entre em contato com seu banco emissor.',
      allows_retrying: 'Apenas 4 vezes em 16 dias.'
    },
    'U3': {
      definition: 'Transação não permitida. Falha na validação dos dados.',
      meaning: 'Transação não permitida. Houve uma falha na validação dos dados. Solicite ao portador que reveja os dados e tente novamente. Se o erro persistir verifique a comunicação entre loja virtual e Cielo.',
      action: 'Transação não permitida. Houve uma falha na validação dos dados. reveja os dados informados e tente novamente. Se o erro persistir entre em contato com a Loja Virtual.',
      allows_retrying: 'Não'
    },
    'GD': {
      definition: 'Transação não permitida',
      meaning: 'Transação não permitida',
      action: 'Transação não é possível ser processada no estabelecimento. Entre em contato com a Cielo para obter mais detalhes Transação',
      allows_retrying: 'Não'
    },
    '0': {
      definition: 'Internal error',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '100': {
      definition: 'RequestId is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '101': {
      definition: 'MerchantId is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '102': {
      definition: 'Payment Type is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '103': {
      definition: 'Payment Type can only contain letters',
      meaning: '',
      action: 'Caracteres especiais não permitidos',
      allows_retrying: ''
    },
    '104': {
      definition: 'Customer Identity is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '105': {
      definition: 'Customer Name is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '106': {
      definition: 'Transaction ID is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '107': {
      definition: 'OrderId is invalid or does not exists',
      meaning: '',
      action: 'Campo enviado excede o tamanho ou contem caracteres especiais',
      allows_retrying: ''
    },
    '108': {
      definition: 'Amount must be greater or equal to zero',
      meaning: '',
      action: 'Valor da transação deve ser maior que 0',
      allows_retrying: ''
    },
    '109': {
      definition: 'Payment Currency is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '110': {
      definition: 'Invalid Payment Currency',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '111': {
      definition: 'Payment Country is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '112': {
      definition: 'Invalid Payment Country',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '113': {
      definition: 'Invalid Payment Code',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '114': {
      definition: 'The provided MerchantId is not in correct format',
      meaning: '',
      action: 'O MerchantId enviado não é um GUID',
      allows_retrying: ''
    },
    '115': {
      definition: 'The provided MerchantId was not found',
      meaning: '',
      action: 'O MerchantID não existe ou pertence a outro ambiente (EX: Sandbox)',
      allows_retrying: ''
    },
    '116': {
      definition: 'The provided MerchantId is blocked',
      meaning: '',
      action: 'Loja bloqueada, entre em contato com o suporte Cielo',
      allows_retrying: ''
    },
    '117': {
      definition: 'Credit Card Holder is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '118': {
      definition: 'Credit Card Number is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '119': {
      definition: 'At least one Payment is required',
      meaning: '',
      action: 'Nó “Payment” não enviado',
      allows_retrying: ''
    },
    '120': {
      definition: 'Request IP not allowed. Check your IP White List',
      meaning: '',
      action: 'IP bloqueado por questões de segurança',
      allows_retrying: ''
    },
    '121': {
      definition: 'Customer is required',
      meaning: '',
      action: 'Nó “Customer” não enviado',
      allows_retrying: ''
    },
    '122': {
      definition: 'MerchantOrderId is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '123': {
      definition: 'Installments must be greater or equal to one',
      meaning: '',
      action: 'Numero de parcelas deve ser superior a 1',
      allows_retrying: ''
    },
    '124': {
      definition: 'Credit Card is Required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '125': {
      definition: 'Credit Card Expiration Date is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '126': {
      definition: 'Credit Card Expiration Date is invalid',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '127': {
      definition: 'You must provide CreditCard Number',
      meaning: '',
      action: 'Numero do cartão de crédito é obrigatório',
      allows_retrying: ''
    },
    '128': {
      definition: 'Card Number length exceeded',
      meaning: '',
      action: 'Numero do cartão superiro a 16 digitos',
      allows_retrying: ''
    },
    '129': {
      definition: 'Affiliation not found',
      meaning: '',
      action: 'Meio de pagamento não vinculado a loja ou Provider inválido',
      allows_retrying: ''
    },
    '130': {
      definition: 'Could not get Credit Card',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '131': {
      definition: 'MerchantKey is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '132': {
      definition: 'MerchantKey is invalid',
      meaning: '',
      action: 'O Merchantkey enviado não é um válido',
      allows_retrying: ''
    },
    '133': {
      definition: 'Provider is not supported for this Payment Type',
      meaning: '',
      action: 'Provider enviado não existe',
      allows_retrying: ''
    },
    '134': {
      definition: 'FingerPrint length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '135': {
      definition: 'MerchantDefinedFieldValue length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '136': {
      definition: 'ItemDataName length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '137': {
      definition: 'ItemDataSKU length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '138': {
      definition: 'PassengerDataName length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '139': {
      definition: 'PassengerDataStatus length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '140': {
      definition: 'PassengerDataEmail length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '141': {
      definition: 'PassengerDataPhone length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '142': {
      definition: 'TravelDataRoute length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '143': {
      definition: 'TravelDataJourneyType length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '144': {
      definition: 'TravelLegDataDestination length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '145': {
      definition: 'TravelLegDataOrigin length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '146': {
      definition: 'SecurityCode length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '147': {
      definition: 'Address Street length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '148': {
      definition: 'Address Number length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '149': {
      definition: 'Address Complement length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '150': {
      definition: 'Address ZipCode length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '151': {
      definition: 'Address City length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '152': {
      definition: 'Address State length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '153': {
      definition: 'Address Country length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '154': {
      definition: 'Address District length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '155': {
      definition: 'Customer Name length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '156': {
      definition: 'Customer Identity length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '157': {
      definition: 'Customer IdentityType length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '158': {
      definition: 'Customer Email length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '159': {
      definition: 'ExtraData Name length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '160': {
      definition: 'ExtraData Value length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '161': {
      definition: 'Boleto Instructions length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '162': {
      definition: 'Boleto Demostrative length exceeded',
      meaning: '',
      action: 'Dado enviado excede o tamanho do campo',
      allows_retrying: ''
    },
    '163': {
      definition: 'Return Url is required',
      meaning: '',
      action: 'URL de retorno não é valida - Não é aceito paginação ou extenções (EX .PHP) na URL de retorno',
      allows_retrying: ''
    },
    '166': {
      definition: 'AuthorizeNow is required',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '167': {
      definition: 'Antifraud not configured',
      meaning: '',
      action: 'Antifraude não vinculado ao cadastro do lojista',
      allows_retrying: ''
    },
    '168': {
      definition: 'Recurrent Payment not found',
      meaning: '',
      action: 'Recorrência não encontrada',
      allows_retrying: ''
    },
    '169': {
      definition: 'Recurrent Payment is not active',
      meaning: '',
      action: 'Recorrência não está ativa. Execução paralizada',
      allows_retrying: ''
    },
    '170': {
      definition: 'Cartão Protegido not configured',
      meaning: '',
      action: 'Cartão protegido não vinculado ao cadastro do lojista',
      allows_retrying: ''
    },
    '171': {
      definition: 'Affiliation data not sent',
      meaning: '',
      action: 'Falha no processamento do pedido - Entre em contato com o suporte Cielo',
      allows_retrying: ''
    },
    '172': {
      definition: 'Credential Code is required',
      meaning: '',
      action: 'Falha na validação das credenciadas enviadas',
      allows_retrying: ''
    },
    '173': {
      definition: 'Payment method is not enabled',
      meaning: '',
      action: 'Meio de pagamento não vinculado ao cadastro do lojista',
      allows_retrying: ''
    },
    '174': {
      definition: 'Card Number is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '175': {
      definition: 'EAN is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '176': {
      definition: 'Payment Currency is not supported',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '177': {
      definition: 'Card Number is invalid',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '178': {
      definition: 'EAN is invalid',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '179': {
      definition: 'The max number of installments allowed for recurring payment is 1',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '180': {
      definition: 'The provided Card PaymentToken was not found',
      meaning: '',
      action: 'Token do Cartão protegido não encontrado',
      allows_retrying: ''
    },
    '181': {
      definition: 'The MerchantIdJustClick is not configured',
      meaning: '',
      action: 'Token do Cartão protegido bloqueado',
      allows_retrying: ''
    },
    '182': {
      definition: 'Brand is required',
      meaning: '',
      action: 'Bandeira do cartão não enviado',
      allows_retrying: ''
    },
    '183': {
      definition: 'Invalid customer bithdate',
      meaning: '',
      action: 'Data de nascimento inválida ou futura',
      allows_retrying: ''
    },
    '184': {
      definition: 'Request could not be empty',
      meaning: '',
      action: 'Falha no formado da requisição. Verifique o código enviado',
      allows_retrying: ''
    },
    '185': {
      definition: 'Brand is not supported by selected provider',
      meaning: '',
      action: 'Bandeira não suportada pela API Cielo',
      allows_retrying: ''
    },
    '186': {
      definition: 'The selected provider does not support the options provided (Capture, Authenticate, Recurrent or Installments)',
      meaning: '',
      action: 'Meio de pagamento não suporta o comando enviado',
      allows_retrying: ''
    },
    '187': {
      definition: 'ExtraData Collection contains one or more duplicated names',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '188': {
      definition: 'Avs with CPF invalid',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '189': {
      definition: 'Avs with length of street exceeded',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '190': {
      definition: 'Avs with length of number exceeded',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '191': {
      definition: 'Avs with length of district exceeded',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '192': {
      definition: 'Avs with zip code invalid',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '193': {
      definition: 'Split Amount must be greater than zero',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '194': {
      definition: 'Split Establishment is Required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '195': {
      definition: 'The PlataformId is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '196': {
      definition: 'DeliveryAddress is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '197': {
      definition: 'Street is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '198': {
      definition: 'Number is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '199': {
      definition: 'ZipCode is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '200': {
      definition: 'City is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '201': {
      definition: 'State is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '202': {
      definition: 'District is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '203': {
      definition: 'Cart item Name is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '204': {
      definition: 'Cart item Quantity is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '205': {
      definition: 'Cart item type is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '206': {
      definition: 'Cart item name length exceeded',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '207': {
      definition: 'Cart item description length exceeded',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '208': {
      definition: 'Cart item sku length exceeded',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '209': {
      definition: 'Shipping addressee sku length exceeded',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '210': {
      definition: 'Shipping data cannot be null',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '211': {
      definition: 'WalletKey is invalid',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '212': {
      definition: 'Merchant Wallet Configuration not found',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '213': {
      definition: 'Credit Card Number is invalid',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '214': {
      definition: 'Credit Card Holder Must Have Only Letters',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '215': {
      definition: 'Agency is required in Boleto Credential',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '216': {
      definition: 'Customer IP address is invalid',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '300': {
      definition: 'MerchantId was not found',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '301': {
      definition: 'Request IP is not allowed',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '302': {
      definition: 'Sent MerchantOrderId is duplicated',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '303': {
      definition: 'Sent OrderId does not exist',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '304': {
      definition: 'Customer Identity is required',
      meaning: '',
      action: 'Campo enviado está vazio ou inválido',
      allows_retrying: ''
    },
    '306': {
      definition: 'Merchant is blocked',
      meaning: '',
      action: 'Merchant está bloqueado',
      allows_retrying: ''
    },
    '307': {
      definition: 'Transaction not found',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '308': {
      definition: 'Transaction not available to capture',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '309': {
      definition: 'Transaction not available to void',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '310': {
      definition: 'Payment method doest not support this operation',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '311': {
      definition: 'Refund is not enabled for this merchant',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '312': {
      definition: 'Transaction not available to refund',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '313': {
      definition: 'Recurrent Payment not found',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '314': {
      definition: 'Invalid Integration',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '315': {
      definition: 'Cannot change NextRecurrency with pending payment',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '316': {
      definition: 'Cannot set NextRecurrency to past date',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '317': {
      definition: 'Invalid Recurrency Day',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '318': {
      definition: 'No transaction found',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '319': {
      definition: 'Smart recurrency is not enabled',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '320': {
      definition: 'Can not Update Affiliation Because this Recurrency not Affiliation saved',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '321': {
      definition: 'Can not set EndDate to before next recurrency',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '322': {
      definition: 'Zero Dollar Auth is not enabled',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    '323': {
      definition: 'Bin Query is not enabled',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S0': {
      definition: 'Serviço indisponível.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S1': {
      definition: 'Cartão inválido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S2': {
      definition: 'TRM indefinido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S3': {
      definition: 'DOC não existe.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S4': {
      definition: 'Dados inválidos.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S5': {
      definition: 'Não existe conf.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S6': {
      definition: 'Tamanho inválido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S7': {
      definition: 'Dados inconsistentes.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S8': {
      definition: 'Senha inválida.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'S9': {
      definition: 'Abrir terminal.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'V0': {
      definition: 'Versão incorreta.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'V1': {
      definition: 'Campo indefinido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'V2': {
      definition: 'Estabelecimento indefinido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'V3': {
      definition: 'CPF/CGC inválido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'V4': {
      definition: 'Operação não permitida',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'V5': {
      definition: 'TRN já efetuada.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'V6': {
      definition: 'Cartão vencido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'V8': {
      definition: 'Documento não encontrado.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'V9': {
      definition: 'Saldo PIN.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'CV': {
      definition: 'Data inválida digitação.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'TM': {
      definition: 'cancel.timeout host.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'VI': {
      definition: 'Valor inválido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'E1': {
      definition: 'Excede parcela.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'E2': {
      definition: 'Valor mínimo parcela inválido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'E3': {
      definition: 'Valor parcela inválido.',
      meaning: '',
      action: '',
      allows_retrying: ''
    },
    'E4': {
      definition: 'Excede prazo.',
      meaning: '',
      action: '',
      allows_retrying: ''
    }
  },

  // REDE
  rede: {
    // CARTÃO PRESENTE
    cp: {
      '00': {
        definition: 'Transação Aprovada',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '01': {
        definition: 'Consultar emissor do cartão',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '03': {
        definition: 'Estabelecimento inválido',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '05': {
        definition: 'Não autorizada (Não aceitar)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '06': {
        definition: 'Erro',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '09': {
        definition: 'Transação em andamento',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '12': {
        definition: 'Transação inválida',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '13': {
        definition: 'Valor da transação inválido',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '14': {
        definition: 'Cartão inválido',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '15': {
        definition: 'Instituição não Cadastrada',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '19': {
        definition: 'Refaça a transação',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '30': {
        definition: 'Erro de formato',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '31': {
        definition: 'Banco não pertence à Rede',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '38': {
        definition: 'Excedido nº de Tentativas da Senha',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '41': {
        definition: 'Cartão extraviado',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '43': {
        definition: 'Cartão roubado',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '51': {
        definition: 'Saldo insuficiente',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '52': {
        definition: 'Conta corrente não cadastrada',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '54': {
        definition: 'Cartão vencido',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '55': {
        definition: 'Senha incorreta',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '56': {
        definition: 'Cartão sem registro',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '57': {
        definition: 'Transação não permitida a esse cliente',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '58': {
        definition: 'Transação não autorizada',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '60': {
        definition: 'Ligar para autorização',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '61': {
        definition: 'Excedido limite de saque',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '73': {
        definition: 'Timeout',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '75': {
        definition: 'Allowable number of pin tries exceeds',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '76': {
        definition: 'Invalid “to” account specified',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '77': {
        definition: 'Referir a transação para o CardHolder',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '78': {
        definition: 'Invalid account specified',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '79': {
        definition: 'Transação não permitida neste ciclo',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '80': {
        definition: 'Transação não existe na base de dados da rede',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '81': {
        definition: 'Transação estornada',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '82': {
        definition: 'Chave de criptografia inválida',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '83': {
        definition: 'Timeout',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '84': {
        definition: 'Invalid authorization life cycle',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '85': {
        definition: 'Not declined',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '86': {
        definition: 'Transação desfeita',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '87': {
        definition: 'Aprovação apenas do valor da compra(Cashback)/aprovação por saldo Voucher',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '89': {
        definition: 'Mensagem enviada pelo HOST',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '90': {
        definition: 'Fechamento contábil',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '91': {
        definition: 'Instituição temporariamente sem comunicação',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '96': {
        definition: 'Erro de sistema',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '101': {
        definition: 'Unauthorized. Problems on the card, contact the issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '102': {
        definition: 'Unauthorized. Check the situation of the store with the issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '103': {
        definition: 'Unauthorized. Please try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '104': {
        definition: 'Unauthorized. Please try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '105': {
        definition: 'Unauthorized. Restricted card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '106': {
        definition: 'Error in issuer processing. Please try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '107': {
        definition: 'Unauthorized. Please try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '108': {
        definition: 'Unauthorized. Value not allowed for this type of card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '109': {
        definition: 'Unauthorized. Nonexistent card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '110': {
        definition: 'Unauthorized. Transaction type not allowed for this card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '111': {
        definition: 'Unauthorized. Insufficient funds.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '112': {
        definition: 'Unauthorized. Expiry date expired.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '113': {
        definition: 'Unauthorized. Identified moderate risk by the issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '114': {
        definition: 'Unauthorized. The card does not belong to the payment network.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '115': {
        definition: 'Unauthorized. Exceeded the limit of transactions allowed in the period.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '116': {
        definition: 'Unauthorized. Please contact the Card Issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '117': {
        definition: 'Transaction not found.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '118': {
        definition: 'Unauthorized. Card locked.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '119': {
        definition: 'Unauthorized. Invalid security code',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '120': {
        definition: 'Zero dollar transaction approved successfully.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '121': {
        definition: 'Error processing. Please try again',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '122': {
        definition: 'Transaction previously sent',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '123': {
        definition: 'Unauthorized. Bearer requested the end of the recurrences in the issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '124': {
        definition: 'Unauthorized. Contact Rede',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '150': {
        definition: 'Timeout. Try again',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '151': {
        definition: 'installments: Greater than allowed',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '153': {
        definition: 'documentNumber: Invalid number',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '154': {
        definition: 'embedded: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '155': {
        definition: 'eci: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '156': {
        definition: 'eci: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '157': {
        definition: 'cavv: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '158': {
        definition: 'capture: Type not allowed for this transaction',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '159': {
        definition: 'userAgent: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '160': {
        definition: 'urls: Required parameter missing (kind)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '161': {
        definition: 'urls: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '167': {
        definition: 'Invalid request JSON',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '169': {
        definition: 'Invalid Content-Type',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '171': {
        definition: 'Operation not allowed for this transaction',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '172': {
        definition: 'Transaction already captured',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '173': {
        definition: 'Authorization expired',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '176': {
        definition: 'urls: Required parameter missing (url)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '200': {
        definition: 'Cardholder successfully authenticated',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '201': {
        definition: 'Authentication not required',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '202': {
        definition: 'Unauthenticated cardholder',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '203': {
        definition: 'Authentication service not registered for the merchant. Please contact Rede',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '204': {
        definition: 'Cardholder not registered in the issuer\'s authentication program',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '220': {
        definition: 'Transaction request with authentication received. Redirect URL sent',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '250': {
        definition: 'onFailure: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '251': {
        definition: 'onFailure: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '252': {
        definition: 'urls: Required parameter missing (url/threeDSecureFailure)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '253': {
        definition: 'urls: Invalid parameter size (url/threeDSecureFailure)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '254': {
        definition: 'urls: Invalid parameter format (url/threeDSecureFailure)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '255': {
        definition: 'urls: Required parameter missing (url/threeDSecureSuccess)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '256': {
        definition: 'urls: Invalid parameter size (url/threeDSecureSuccess)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '257': {
        definition: 'urls: Invalid parameter format (url/threeDSecureSuccess)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '258': {
        definition: 'userAgent: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '259': {
        definition: 'urls: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '260': {
        definition: 'urls: Required parameter missing (kind/threeDSecureFailure)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '261': {
        definition: 'urls: Required parameter missing (kind/threeDSecureSuccess)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '351': {
        definition: 'Forbidden',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '353': {
        definition: 'Transaction not found',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '354': {
        definition: 'Transaction with period expired for refund',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '355': {
        definition: 'Transaction already canceled.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '357': {
        definition: 'Sum of amount refunds greater than the transaction amount',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '358': {
        definition: 'Sum of amount refunds greater than the value processed available for refund',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '359': {
        definition: 'Refund successful',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '360': {
        definition: 'Refund request has been successful',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '362': {
        definition: 'RefundId not found',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '363': {
        definition: 'Callback Url characters exceeded 500',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '365': {
        definition: 'Partial refund not available.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '368': {
        definition: 'Unsuccessful. Please try again',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '369': {
        definition: 'Refund not found',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '370': {
        definition: 'Request failed. Contact Rede',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '371': {
        definition: 'Transaction not available for refund. Try again in a few hours',
        meaning: '',
        action: '',
        allows_retrying: ''
      }
    },

    // CARTÃO NÃO PRESENTE
    cnp: {
      '0': {
        definition: 'Success',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '101': {
        definition: 'Unauthorized. Problems on the card, contact the issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '102': {
        definition: 'Unauthorized. Check the situation of the store with the issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '103': {
        definition: 'Unauthorized. Please try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '104': {
        definition: 'Unauthorized. Please try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '105': {
        definition: 'Unauthorized. Restricted card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '106': {
        definition: 'Error in issuer processing. Please try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '107': {
        definition: 'Unauthorized. Please try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '108': {
        definition: 'Unauthorized. Value not allowed for this type of card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '109': {
        definition: 'Unauthorized. Nonexistent card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '110': {
        definition: 'Unauthorized. Transaction type not allowed for this card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '111': {
        definition: 'Unauthorized. Insufficient funds.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '112': {
        definition: 'Unauthorized. Expiry date expired.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '113': {
        definition: 'Unauthorized. Identified moderate risk by the issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '114': {
        definition: 'Unauthorized. The card does not belong to the payment network.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '115': {
        definition: 'Unauthorized. Exceeded the limit of transactions allowed in the period.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '116': {
        definition: 'Unauthorized. Please contact the Card Issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '117': {
        definition: 'Transaction not found.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '118': {
        definition: 'Unauthorized. Card locked.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '119': {
        definition: 'Unauthorized. Invalid security code',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '120': {
        definition: 'Zero dollar transaction approved successfully.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '121': {
        definition: 'Error processing. Please try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '122': {
        definition: 'Transaction previously sent',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '123': {
        definition: 'Unauthorized. Bearer requested the end of the recurrences in the issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '124': {
        definition: 'Unauthorized. Contact Rede',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '170': {
        definition: 'Zero dollar transaction not allowed for this card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '172': {
        definition: 'transaction already captured.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '174': {
        definition: 'Zero dollar transaction success.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '175': {
        definition: 'Zero dollar transaction denied.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '1': {
        definition: 'expirationYear: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '2': {
        definition: 'expirationYear: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '3': {
        definition: 'expirationYear: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '4': {
        definition: 'cavv: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '5': {
        definition: 'cavv: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '6': {
        definition: 'postalCode: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '7': {
        definition: 'postalCode: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '8': {
        definition: 'postalCode: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '9': {
        definition: 'complement: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '10': {
        definition: 'complement: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '11': {
        definition: 'departureTax: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '12': {
        definition: 'documentNumber: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '13': {
        definition: 'documentNumber: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '14': {
        definition: 'documentNumber: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '15': {
        definition: 'securityCode: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '16': {
        definition: 'securityCode: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '17': {
        definition: 'distributorAffiliation: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '18': {
        definition: 'distributorAffiliation: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '19': {
        definition: 'xid: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '20': {
        definition: 'eci: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '21': {
        definition: 'xid: Required parameter for Visa card is missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '22': {
        definition: 'street: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '23': {
        definition: 'street: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '24': {
        definition: 'affiliation: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '25': {
        definition: 'affiliation: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '26': {
        definition: 'affiliation: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '27': {
        definition: 'Parameter cavv or eci missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '28': {
        definition: 'code: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '29': {
        definition: 'code: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '30': {
        definition: 'code: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '31': {
        definition: 'softdescriptor: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '32': {
        definition: 'softdescriptor: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '33': {
        definition: 'expirationMonth: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '34': {
        definition: 'code: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '35': {
        definition: 'expirationMonth: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '36': {
        definition: 'cardNumber: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '37': {
        definition: 'cardNumber: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '38': {
        definition: 'cardNumber: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '39': {
        definition: 'reference: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '40': {
        definition: 'reference: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '41': {
        definition: 'reference: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '42': {
        definition: 'reference: Order number already exists',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '43': {
        definition: 'number: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '44': {
        definition: 'number: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '45': {
        definition: 'number: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '46': {
        definition: 'installments: Not correspond to authorization transaction',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '47': {
        definition: 'origin: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '49': {
        definition: 'The value of the transaction exceeds the authorized',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '50': {
        definition: 'installments: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '51': {
        definition: 'Unauthorized. Contact issuer. (Insufficient funds)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '53': {
        definition: 'Transaction not allowed for the issuer. Contact Rede.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '54': {
        definition: 'installments: Parameter not allowed for this transaction',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '55': {
        definition: 'cardHolderName: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '56': {
        definition: 'Error in reported data. Try again.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '57': {
        definition: 'affiliation: Invalid merchant',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '58': {
        definition: 'Unauthorized. Contact issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '59': {
        definition: 'cardHolderName: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '60': {
        definition: 'street: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '61': {
        definition: 'subscription: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '63': {
        definition: 'softdescriptor: Not enabled for this merchant',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '64': {
        definition: 'Transaction not processed. Try again',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '65': {
        definition: 'token: Invalid token',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '66': {
        definition: 'departureTax: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '67': {
        definition: 'departureTax: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '68': {
        definition: 'departureTax: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '69': {
        definition: 'Transaction not allowed for this product or service.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '70': {
        definition: 'amount: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '71': {
        definition: 'amount: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '72': {
        definition: 'Contact issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '73': {
        definition: 'amount: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '74': {
        definition: 'Communication failure. Try again',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '75': {
        definition: 'departureTax: Parameter should not be sent for this type of transaction',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '76': {
        definition: 'kind: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '78': {
        definition: 'Transaction does not exist',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '79': {
        definition: 'Expired card. Transaction cannot be resubmitted. Contact issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '80': {
        definition: 'Unauthorized. Contact issuer. (Insufficient funds)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '82': {
        definition: 'Unauthorized transaction for debit card.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '83': {
        definition: 'Unauthorized. Contact issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '84': {
        definition: 'Unauthorized. Transaction cannot be resubmitted. Contact issuer.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '85': {
        definition: 'complement: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '86': {
        definition: 'Expired card',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '87': {
        definition: 'At least one of the following fields must be filled: tid or reference',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '88': {
        definition: 'Merchant not approved. Regulate your website and contact the Rede to return to transact.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '89': {
        definition: 'token: Invalid token',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '97': {
        definition: 'tid: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '98': {
        definition: 'tid: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '132': {
        definition: 'DirectoryServerTransactionId: Invalid parameter size.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '133': {
        definition: 'ThreedIndicator: Invalid parameter value.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '150': {
        definition: 'Timeout. Try again',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '151': {
        definition: 'installments: Greater than allowed',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '153': {
        definition: 'documentNumber: Invalid number',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '154': {
        definition: 'embedded: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '155': {
        definition: 'eci: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '156': {
        definition: 'eci: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '157': {
        definition: 'cavv: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '158': {
        definition: 'capture: Type not allowed for this transaction',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '159': {
        definition: 'userAgent: Invalid parameter size',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '160': {
        definition: 'urls: Required parameter missing (kind)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '161': {
        definition: 'urls: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '167': {
        definition: 'Invalid request JSON',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '169': {
        definition: 'Invalid Content-Type',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '171': {
        definition: 'Operation not allowed for this transaction',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '173': {
        definition: 'Authorization expired',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '176': {
        definition: 'urls: Required parameter missing (url)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '899': {
        definition: 'Unsuccessful. Please contact Rede.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '1018': {
        definition: 'MCC Invalid Size.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '1019': {
        definition: 'MCC Parameter Required.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '1020': {
        definition: 'MCC Invalid Format.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '1021': {
        definition: 'PaymentFacilitatorID Invalid Size.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '1023': {
        definition: 'PaymentFacilitatorID Invalid Format.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '1030': {
        definition: 'CitySubMerchant Invalid Size.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '1034': {
        definition: 'CountrySubMerchant Invalid Size.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '200': {
        definition: 'Cardholder successfully authenticated',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '201': {
        definition: 'Authentication not required',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '202': {
        definition: 'Unauthenticated cardholder',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '203': {
        definition: 'Authentication service not registered for the merchant. Please contact Rede',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '204': {
        definition: 'Cardholder not registered in the issuer\'s authentication program',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '220': {
        definition: 'Transaction request with authentication received. Redirect URL sent',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '250': {
        definition: 'onFailure: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '251': {
        definition: 'onFailure: Invalid parameter format',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '252': {
        definition: 'urls: Required parameter missing (url/threeDSecureFailure)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '253': {
        definition: 'urls: Invalid parameter size (url/threeDSecureFailure)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '254': {
        definition: 'urls: Invalid parameter format (url/threeDSecureFailure)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '255': {
        definition: 'urls: Required parameter missing (url/threeDSecureSuccess)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '256': {
        definition: 'urls: Invalid parameter size (url/threeDSecureSuccess)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '257': {
        definition: 'urls: Invalid parameter format (url/threeDSecureSuccess)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '258': {
        definition: 'userAgent: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '259': {
        definition: 'urls: Required parameter missing',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '260': {
        definition: 'urls: Required parameter missing (kind/threeDSecureFailure)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '261': {
        definition: 'urls: Required parameter missing (kind/threeDSecureSuccess)',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '351': {
        definition: 'Forbidden',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '353': {
        definition: 'Transaction not found',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '354': {
        definition: 'Transaction with period expired for refund',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '355': {
        definition: 'Transaction already canceled.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '357': {
        definition: 'Sum of amount refunds greater than the transaction amount',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '358': {
        definition: 'Sum of amount refunds greater than the value processed available for refund',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '359': {
        definition: 'Refund successful',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '360': {
        definition: 'Refund request has been successful',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '362': {
        definition: 'RefundId not found',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '363': {
        definition: 'Callback Url characters exceeded 500',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '365': {
        definition: 'Partial refund not available.',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '368': {
        definition: 'Unsuccessful. Please try again',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '369': {
        definition: 'Refund not found',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '370': {
        definition: 'Request failed. Contact Rede',
        meaning: '',
        action: '',
        allows_retrying: ''
      },
      '371': {
        definition: 'Transaction not available for refund. Try again in a few hours',
        meaning: '',
        action: '',
        allows_retrying: ''
      }
    }
  }
}
