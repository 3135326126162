export default {
  'NoPermission': {
    title: 'Este usuário não tem permissão para acessar esta conta.',
    message: 'Verifique se possui as credenciais corretas e tente novamente.'
  },
  'Unauthorized': {
    title: 'Seu e-mail ou senha não conferem.',
    message: 'Que tal tentar de novo?'
  },
  'Invalid credentials': {
    title: 'Seu e-mail ou senha não conferem.',
    message: 'Que tal tentar de novo?'
  }
}
